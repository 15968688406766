import React from 'react';
import Layout, { buildProps } from './index.js';
import Article from './main-components/Article';
import ArticlePreview from './main-components/ArticlePreview';
import MapModule from './main-components/components/CityGuide/MapModule.js';
import OneEntry from './main-components/components/Article/OneEntry.js';
import HotelMapData from './main-components/components/Article/HotelMapData.js';
const Component = (props) => {
  const {
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    pages,
    paths,
    latestIds,
    location
  } = buildProps(props)

  const addedProps = {
    pages,
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    paths
  }
  
  if (location.search.indexOf('preview=true') !== -1) {
    return <ArticlePreview
      {...addedProps}
      moduleOverrides={{
        OneEntry,
        MapModule,
        HotelMapData
      }}
      latestIds={latestIds} />
  }
  return <Article
    {...addedProps}
    ignoreAds
    {...addedProps}
    moduleOverrides={{
      OneEntry,
      MapModule,
      HotelMapData
    }}
    latestIds={latestIds} />
}

const Page = (props) => {
  return <Layout {...props}><Component {...props} /></Layout>
}

export default Page