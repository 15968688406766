import React from 'react'
import MapModule from '../CityGuide/MapModule'
import { LinkRenderer } from '../../../lib/utils'
import { sContext } from '../../../context'

import ReactMarkdown from '../../../lib/ReactMarkdown'

class OneEntry extends React.PureComponent {
  localePath = useContext(sContext).currentLocale

  state = {
    mapModule: this.props.subSections[0]
      ? this.props.subSections[0]
      : undefined,
    mapCenter: {
      lat: this.props.subSections[0].fields.mapCenter.lat,
      lon: this.props.subSections[0].fields.mapCenter.lon
    }
  }

  render() {
    return (
      <div className="row oneentry-main">
        <MapModule
          mapColour={this.state.mapModule.fields.mapColour}
          mapMarkers={this.state.mapModule.fields.mapMarkers}
          mapZoom={this.state.mapModule.fields.mapZoom}
          mapCenter={this.state.mapCenter}
        />
        <div className="col-lg-12 oneentry-info">
          <div className="row oneentry-tag">
            <span>{this.props.credit}</span>
          </div>
          <div className="row oneentry-address">
            <ReactMarkdown
              source={this.props.text.split('<')[0]}
              excapeHTML={false}
              renderers={{ link: LinkRenderer }}
            />
          </div>
          <div className="row oneentry-links">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.props.text.split('<')[1].replace('>', '')}
            >
              WWW
            </a>
            &nbsp;|&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.props.text.split('<')[2].replace('>', '')}
            >
              {this.localePath === 'en' ? 'MAP' : 'CARTE'}
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default OneEntry
