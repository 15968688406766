import React, { useContext } from 'react'
import MapModule from '../CityGuide/MapModule'
import { LinkRenderer } from '../../../lib/utils'
import ReactMarkdown from '../../../lib/ReactMarkdown'
import { sContext } from '../../../context'

const RowTable = ({ data }) => {
  if (data.length % 2 !== 0) {
    data.shift()
  }

  return (
    <>
      {data.map((_, i) => {
        if (i % 2 !== 0) {
          return undefined
        }

        return (
          <div key={`hotelmapdata-${i}`} className="row hotelmapdata-row">
            <div className={`col-lg-1 hotelmapdata-row-icon ${data[i][1]}`} />
            <div className={`col-lg-5 hotelmapdata-row-left`}>{data[i][0]}</div>
            <div
              className={`col-lg-1 hotelmapdata-row-icon ${data[i + 1][1]}`}
            />
            <div className={`col-lg-5 hotelmapdata-row-right`}>
              {data[i + 1][0]}
            </div>
          </div>
        )
      })}
    </>
  )
}

const HotelMapData = ({
  text,
  credit,
  aggregatorTag,
  subSections: [
    {
      fields: {
        mapColour,
        mapZoom,
        mapCenter: { lat, lon }
      }
    }
  ]
}) => {
  const localePath = useContext(sContext).currentLocale

  const mapCenter = {
    lat,
    lon
  }

  const textSplit = text.split('<')

  let data = []
  let splitTexts

  for (let texts of aggregatorTag || []) {
    const isAnimal =
      texts.toLowerCase().includes('pets') ||
      texts.toLowerCase().includes('animaux')
    const isRooms =
      texts.toLowerCase().includes('rooms') ||
      texts.toLowerCase().includes('chambres')
    const isWifi =
      texts.toLowerCase().includes('wifi') ||
      texts.toLowerCase().includes('wi-fi')
    const isPool = texts.toLowerCase().includes('pool')

    let text
    let langText

    if (isRooms || isAnimal || isWifi) {
      splitTexts = texts.split(isRooms ? '=' : ' ')
      const firstText = splitTexts[0].trim()
      const secondText = splitTexts[1].trim()

      if (isRooms) {
        langText = localePath === 'en' ? firstText : 'chambres'
        text = `${secondText} ${firstText}`
        data.push([text, firstText, secondText])
      } else if (isAnimal) {
        langText =
          localePath === 'en'
            ? `${firstText} ${secondText}`
            : 'animaux acceptés'
        data.push([langText, firstText])
      } else if (isWifi) {
        langText =
          localePath === 'en'
            ? `${firstText} ${secondText}`
            : `${secondText} gratuit`
        data.push([langText, secondText])
      }
    } else if (isPool) {
      langText = localePath === 'en' ? 'pool' : 'piscine'
      data.push([langText, 'pool'])
    } else {
      data.push([texts, texts])
    }
  }

  return (
    <div
      className={`
      row
      hotelmapdata-main
      ${aggregatorTag && aggregatorTag.length ? '' : 'missing-information'}
    `}
    >
      <MapModule
        mapColour={mapColour}
        mapZoom={mapZoom}
        mapCenter={mapCenter}
        title={credit}
        aggregatorTag={aggregatorTag}
        hasOffset={aggregatorTag && aggregatorTag.length}
      />
      <div className={'col-lg-6 hotelmapdata-overlay'}>
        <div className="row hotelmapdata-data">
          <div className="col-sm-8 offset-2 hotelmapdata-wrapper">
            {data.length % 2 !== 0 && (
              <div className="row hotelmapdata-rooms">
                <div className="col-lg-1 hotelmapdata-rooms-icon rooms" />
                <div className="col-lg-9 hotelmapdata-rooms-text">
                  {localePath === 'en'
                    ? 'number of rooms'
                    : 'nombre de chambres'}
                </div>
                <div className="data col-lg-2 hotelmapdata-rooms-data">
                  {data[0][2]}
                </div>
              </div>
            )}
            <RowTable data={data} />
          </div>
        </div>
      </div>
      <div
        className={`
        col-lg-${aggregatorTag && aggregatorTag.length ? '6' : '12'}
        hotelmapdata-info
      `}
      >
        <div className="hotelmapdata-info-container">
          <div className="bottom-portion">
            <div className="row hotelmapdata-address">
              {textSplit.length ? (
                <ReactMarkdown
                  source={textSplit[0].split('\n').join(' \n \n ')}
                  escapeHtml={false}
                  renderers={{ link: LinkRenderer }}
                />
              ) : null}
            </div>
            <div className="row hotelmapdata-links">
              {textSplit.length > 1 && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={textSplit[1].replace('>', '')}
                >
                  WWW
                </a>
              )}
              {textSplit[1] ? '\u00A0|\u00A0' : null}
              {textSplit[1] ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={textSplit[2].replace('>', '')}
                >
                  {localePath === 'en' ? 'MAP' : 'CARTE'}
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HotelMapData
